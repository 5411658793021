.atlasContainer {
  margin-block: 1%;
  padding: 1%;
  overflow-x: hidden;
  padding-right: 3%;
}

/* .atlasContainer::-webkit-scrollbar {
  width: 0.6em;
}

.atlasContainer::-webkit-scrollbar-track {
  background-color: rgba(128, 128, 128, 0.441);
  border-radius: 1.5em;
  margin-block: .5em;
}

.atlasContainer::-webkit-scrollbar-thumb {
  background-color: #B7C8E9;
  border-radius: 1.5em;
}

.atlasContainer::-webkit-scrollbar-thumb:hover {
  background-color: #90aee979;

} */