.header {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  background-color: #fff;
  /* border-bottom: 1px solid #e5e5e5; */
}

.titleRow {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.title {
  font-size: 28px;
  margin: 0;
}

.right {
  display: flex;
  align-items: center;
}