.bar{
fill:#3088cb;
}

.bar:hover {
    fill: #ff9831;
}

.x-axis {
    font-style: normal;
    font-family:inherit;
    font-weight: 500;
    stroke-width: 1.75;
    margin-bottom: 200px;
}

.y-axis {
    font-style: normal;
    font-family:inherit;
    font-weight: 500;
    stroke-width: 1.75;
}

.tick line{
    opacity: 25%;
}

.domain{
    stroke: #2e2e36;
    stroke-width: 1.75;
}

.title{
    font-family: inherit;
    font-weight: 650;
}

.label{
    font-style: normal;
    font-family:inherit;
    font-weight: 500;
    stroke-width: 1.75;
    text-anchor: inherit;
    
}