.headerView {
  --header-height: 75px;
}

.content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 12px 60px 0 60px;
  box-sizing: border-box;
}