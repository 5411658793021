.sidebarWrap {
  width: 100%;
  max-height: 50vh;
  color: #f5f5f5;
  display: flex;
  justify-content: center;
}

.sidebarNav {
  background: #002644;
  width: 4rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  box-shadow: 1px 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.iconList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.listItemIcon {
  min-width: 0 !important;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
}

.navbarItemContainer {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px;
}

.navbarItemContainer:hover {
  background: #8DA3EE;
}

.geneIcon {
  height: 20px;
  transform: rotate(30deg);
}

.navlink {
  text-decoration: none;
  color: white;
}

.navlinkIcon {
  text-decoration: none;
  color: white;
}

.navlinkIcon:hover {
  color: #8DA3EE;
}

.coloredIcon {
  color: #00579B;
  font-size: 1.2rem;
}

.list {
    height: 90%;
    justify-content: space-between;
    margin: 24px 0;
}
