ul{
    display: flex;
    list-style:none;
    align-items: center;
}

.logoName{
    font-size: 24px;
    font-weight: 100;
}

.appbar{
    z-index: 20000; /* to keep it above content */
    width: 100%;
    /* justify-content : space-between; */
    background-color: white;
    position: fixed;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.logo {
    fill: black;
    stroke: black;
}

.navbarcontent{
    text-decoration: none;
    font-size: 1.1rem ;
    color: black;
    margin-left: 3rem;
}

.navbarcontent:hover {
    color: #347AE1;
}