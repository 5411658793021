.visualizationContainer{
    height: 1000px;
    transform: scale(0.8, 0.8) translate(0, 30px);
    -ms-transform: scale(0.8, 0.8) translate(0, 30px); /* IE 9 */
    -webkit-transform: scale(0.8, 0.8) translate(0, 30px); /* Safari and Chrome */
    -o-transform: scale(0.8, 0.8) translate(0, 30px); /* Opera */
    -moz-transform: scale(0.8, 0.8) translate(0, 30px); /* Firefox */
}
.headerContainer{
    text-align: center;
    align-items: center;
    padding-top: 0px;
}
.formContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 1000px;
    height: 1500px;
}

.textContainer {
    display: flex;
    justify-content: center
}