.accessRightIndicator {
  color: gray;
  font-size: 14px;
  margin-left: 6px;
  border-radius: 25px;
  border: 1px solid gray;
  padding: 0px 5px 0px 5px;
}

.cardSpacing {
  margin-bottom: 16px;
}
