.axis line {
  stroke: lightgrey;
}

.axis path {
  stroke: lightgrey;
}

.axis text {
  fill: lightgrey;
}

text {
  pointer-events: none;
  color: #ffffff;
}