.profileImage {
  position: relative;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.editButton {
  position: absolute;
  bottom: 0;
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(24, 64, 96, 0.8);
}

.editButton>span {
  font-weight: bold;
  color: #fff;
}