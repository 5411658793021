.cardContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}

.clickable {
  cursor: pointer;
}

.cardImageWrapper {
  margin: 2px 0;
  margin-right: 12px;
  border-radius: 50%;
}

.start {
  display: flex;
  align-items: center;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.title {
  margin: 0;
  font-weight: 500;
}

.description {
  margin: 0;
  font-size: 14px;
  color: gray;
}

.description>p {
  margin: 0;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.trailing {
  display: flex;
  align-items: center;
}