.headerContainer{
    text-align: center;
    align-items: center;
    padding-top: 200px;
}
.formContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 1000px;
    height: 1500px;
}

.textContainer {
    display: flex;
    justify-content: center
}